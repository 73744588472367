@import 'markets';

$isDebug: false;
$mobileWidth: 1000px;

.landing-page-or-manifesto-container {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.youtube {
  height: 100vh;
  width: 100vw;
  position: absolute;
  display: flex;
  transform: scale(1.1, 1.1);
}

video, img {
  top: 0;
  left: 0;
  height: 100vh;
}

.video-overlay {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
}

.landing-page-or-manifesto {
  left: 0;
  top: 0;
  position: absolute;
  color: white;
  font-family: Raleway;
}

.landing-page {
  height: 100vh;
  width: 100vw;
  font-size: 3vmin;
}

.center-text {
  margin-top: 30vh;
  margin-left: 8vw;
  display: flex;
  flex-direction: column;
  @media (max-width: $mobileWidth) {
    margin-top: 15vh;
    margin-left: 0;
    padding: 8%;
  }
}

.tagline {
  @if $isDebug {
    background-color: green;
  }
  font-family: PlayfairDisplay;
  font-size: 8vmin;
  padding-bottom: 5vh;
  animation-name: fadeInLeft;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(.05,.74,.79,1);
  animation-fill-mode: both;
  animation-duration: 0.6s;
  @media (max-width: $mobileWidth) {
    font-size: 12vmin;
  }
}
.tagline-delay {
  animation-delay: 0.4s;
}

.subtagline {
  @if $isDebug {
    background-color: red;
  }
  width: 35%;
  font-size: 2.1vmin;
  padding-bottom: 5vh;
  animation-name: fadeInLeft;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(.05,.74,.79,1);
  animation-fill-mode: both;
  animation-duration: 0.4s;
  @media (max-width: $mobileWidth) {
    width: 90%;
    font-size: 5.0vmin;
  }
}
.subtagline-delay {
  animation-delay: 0.6s;
}

.cursor {
  animation: blinker 0.9s linear infinite;
}

@keyframes blinker {
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.hover-lines {
  width: 60%;
  display: flex;
  flex-direction: row;
  animation-name: fadeInLeft;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(.05,.74,.79,1);
  animation-fill-mode: both;
  animation-duration: 0.3s;
  @media (max-width: $mobileWidth) {
    width: auto;
    flex-direction: column;
  }
}
.hover-lines-delay {
  animation-delay: 0.7s;
}

.nodelay {
  animation-delay: 0s !important;
}

.hover-line-container {
  width: 28%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  @media (max-width: $mobileWidth) {
    width: 100%;
    padding-bottom: 6%;
  }
}

.hover-line-line {
  background-color: red;
  width: 50px;
  height: 3px;
  background: white;
  transition: transform 0.2s ease-in;
}

.line-expand {
  transform: translateX(10%) scaleX(1.2);
}
.line-contract {
  transform: translateX(0%) scaleX(1.0);
}
.text-expand {
  transform: translateX(8%)
}
.text-contract {
  transform: translateX(0%)
}

.hover-line-text {
  @if $isDebug {
    background-color: blue;
  }
  transition: transform 0.2s ease-in;
  padding-left: 7%;
  font-size: 1.6vmin;
  @media (max-width: $mobileWidth) {
    font-size: 4.5vmin;
  }
}

.social {
  @if $isDebug {
    background-color: blue;
  }
  position: absolute;
  right: 0;
  padding-top: 5vmin;
  padding-right: 10vmin;
  @media (max-width: $mobileWidth) {
    padding-right: 6vmin;
  }
}

.social-icon {
  padding-left: 5vmin;
  color: white;
}

.manifesto {
  overflow-y: scroll;
  max-height: 100%;
  animation-duration: 0.3s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(.05,.74,.79,1);
  animation-fill-mode: both;
}

.manifesto-wrapper {
  padding-left: 30%;
  padding-right: 30%;
  padding-top: 5%;
  padding-bottom: 48%;
  @media (max-width: $mobileWidth) {
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 15%;
    padding-bottom: 100%;
  }
}

.manifesto-title {
  font-family: PlayfairDisplay;
  text-align: center;
  font-size: 8vmin;
  padding-bottom: 5%;
  @media (max-width: $mobileWidth) {
    font-size: 13vmin;
  }
}

.manifesto-content {
}

div.manifesto {
  font-size: 2.2vmin;
  @media (max-width: $mobileWidth) {
    font-size: 5.5vmin;
  }
}

a {
  color: #34c5fa;
}

p.center {
  text-align: center;
}

li {
  margin-top: -1%;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-8%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(2%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.fade-in-left {
  animation-name: fadeInLeft;
}
.fade-in-right {
  animation-name: fadeInRight;
}

