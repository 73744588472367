.account {
  @if $isDebug {
    background-color: green;
  }
  top: 0;
  bottom: 0;
  width: 40vw;
  overflow: hidden;
  text-align: center;
  padding-top: 4vmin;
  padding-bottom: 4vmin;
  padding-left: 8vmin;
  padding-right: 4vmin;
  @media (max-width: $mobileWidth) {
    width: 92vw;
    padding: 4vmin;
  }
}

.account-title {
  padding-top: 5%;
  font-size: 7vmin;
  font-family: PlayfairDisplay;
  @media (max-width: $mobileWidth) {
    font-size: 12vmin;
  }
}

.account-prelogin-howitworks-title {
  padding-top: 15%;
  font-size: 4vmin;
  font-family: PlayfairDisplay;
  text-align: left;
  @media (max-width: $mobileWidth) {
    margin-left: 5%;
    font-size: 9vmin;
  }
}

.account-prelogin-howitworks-divider {
  width: 15%;
  height: 3px;
  margin-top: 3%;
  background: white;
  @media (max-width: $mobileWidth) {
    margin-left: 5%;
  }
}

.account-prelogin-howitworks-body {
  padding-top: 4%;
  padding-bottom: 5%;
  font-size: 1.8vmin;
  text-align: left;
  padding-right: 15%;
  @media (max-width: $mobileWidth) {
    margin-left: 5%;
    margin-right: 5%;
    font-size: 5.25vmin;
    padding: 0;
  }
}

.account-login-button {
  width: 35%;
  height: 5vmin;
  font-size: 2.0vmin;
  background-color: $backgroundColor;
  @media (max-width: $mobileWidth) {
    width: 70%;
    height: auto;
    font-size: 4vmin;
    padding: 1.5%;
    margin-top: 5%;
  }
}

.account-logout-button {
  left: 0;
  bottom: 0;
  margin-left: 3%;
  margin-bottom: 2%;
  position: absolute;
  width: 10%;
  padding: 0.5%;
  font-size: 1.7vmin;
  background-color: $backgroundColor;
  @media (max-width: $mobileWidth) {
    position: relative;
    padding: 1.5%;
    margin: 0 auto;
    margin-bottom: 5%;
    width: 50%;
    font-size: 4vmin;
  }
}

.account-postlogin-newuser {
  font-size: 2.8vmin;
  margin: 0 auto;
  padding-top: 5%;
  width: 70%;
  @media (max-width: $mobileWidth) {
    font-size: 5.5vmin;
    margin-left: 5%;
    margin-right: 5%;
    padding-top: 8%;
    width: auto;
  }
}

.account-postlogin-cash {
  padding-top: 5%;
}

.account-postlogin-dividends {
  font-size: 2.3vmin;
  @media (max-width: $mobileWidth) {
    font-size: 3.8vmin;
  }
}

.account-postlogin-positions {
  padding-top: 7%;
}

.account-postlogin-position {
  font-size: 2.5vmin;
  @media (max-width: $mobileWidth) {
    font-size: 4.0vmin;
  }
}

.account-postlogin-openlimitorders {
  padding-top: 7%;
  margin: 0 auto;
}

.account-postlogin-openlimitorder {
  font-size: 2.5vmin;
  @media (max-width: $mobileWidth) {
    font-size: 4.0vmin;
  }
}

.account-postlogin-openlimitorder-cancel {
  font-size: 2.0vmin;
  cursor: pointer;
  @media (max-width: $mobileWidth) {
    font-size: 3.0vmin;
  }
}

.account-postlogin-externaltransactions {
  padding-top: 5%;
  margin: 0 auto;
}

.account-postlogin-depositwithdraw-button {
  width: 47%;
  margin-left: 1%;
  margin-right: 1%;
  font-size: 2.0vmin;
  float: left;
  height: 4vmin;
  line-height: 4vmin;
  @media (max-width: $mobileWidth) {
    width: 70%;
    margin: 0 auto;
    font-size: 4.5vmin;
    float: none;
    margin-top: 2%;
    padding: 2.5%;
  }
}
.account-postlogin-depositwithdraw-button-single {
  width: 47%;
  font-size: 2.0vmin;
  line-height: 5vmin;
  margin: 0 auto;
  @media (max-width: $mobileWidth) {
    width: 70%;
    font-size: 4.5vmin;
    padding: 2.5%;
  }
}

.account-postlogin-depositwithdraw-revealed {
  width: 96%;
  font-size: 2.0vmin;
  padding-top: 2%;
  padding-bottom: 2%;
  margin: 0 auto;
  @media (max-width: $mobileWidth) {
    width: 70%;
    margin: 0 auto;
    font-size: 4.5vmin;
    margin-bottom: 2%;
    padding: 2.5%;
  }
}
.account-postlogin-depositwithdraw-revealed-single {
  width: 70%;
  font-size: 2.0vmin;
  padding-top: 2%;
  padding-bottom: 2%;
  margin: 0 auto;
  @media (max-width: $mobileWidth) {
    font-size: 4.5vmin;
    padding: 2.5%;
  }
}

.account-postlogin-depositwithdraw-revealed-content {
  padding-top: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.account-postlogin-depositwithdraw-revealed-content-input {
  margin-left: 1%;
  margin-right: 1%;
  width: 20%;
  background-color: $backgroundColor;
  border: 1px solid white;
  border-radius: 3px;
  color: white;
  font-size: 1.8vmin;
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  @media (max-width: $mobileWidth) {
    margin-left: 2%;
    margin-right: 2%;
    width: 40%;
    font-size: 4vmin;
    padding-left: 2%;
    padding-right: 2%;
  }
}

