@font-face {
  font-family: PlayfairDisplay;
  src: url("./fonts/PlayfairDisplay-Bold/PlayfairDisplay-Bold-LiningNums.woff");
}

@font-face {
  font-family: Raleway;
  src: url("./fonts/RalewayRegular/RalewayRegular.woff");
}

@font-face {
  font-family: Raleway;
  src: url("./fonts/RalewayBold/RalewayBold.woff");
  font-weight: bold;
}

