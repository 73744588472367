$isDebug: false;
$mobileWidth: 1000px;

$backgroundColor: #253237;
$color1: #e0fbfc;
$color2: #c2dfe3;
$color3: #9db4c0;
$color4: #5c6b73;

@import 'markets/account';
@import 'markets/ticker';

.markets {
  height: 100vh;
  width: 100vw;
  background-color: $backgroundColor;
  color: white;
  font-family: Raleway;
  font-size: 3vmin;
  display: flex;
  flex-direction: row;
  position: relative;
  @media (max-width: $mobileWidth) {
    flex-direction: column;
    font-size: 7vmin;
  }
}

button {
  @extend .account-button
}

.account-button {
  cursor: pointer;
  font-family: Raleway;
  color: white;
  border: 1px solid white;
  border-radius: 5px;
  text-align: center;
}
.account-button:hover {
  box-shadow: 0 0 20px 2px $color4;
}

