.tickers {
  @if $isDebug {
    background-color: blue;
  }
  top: 0;
  bottom: 0;
  width: 60vw;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 4vmin;
  padding-bottom: 4vmin;
  padding-right: 4vmin;
  @media (max-width: $mobileWidth) {
    width: 92vw;
    padding: 4vmin;
  }
}

.ticker {
  @if $isDebug {
    background-color: red;
  }
  text-align: center;
  background-size: cover;
  box-shadow: 0 0 16px 16px $backgroundColor inset;
  transition: transform 0.05s ease-in;
}
.ticker-pointer {
  cursor: pointer;
}
.ticker-transform {
  transform: scale(1.04);
}

$tickerPadding: 23%;
.ticker-background-overlay {
  background-color: rgba($backgroundColor, 0.6);
  padding-top: $tickerPadding;
  padding-bottom: $tickerPadding;
}

.ticker-title {
  font-size: 3vmin;
  @media (max-width: $mobileWidth) {
    font-size: 6vmin;
  }
}

.ticker-interest {
  @if $isDebug {
    background-color: teal;
  }
  font-size: 2.6vmin;
  padding-top: 2.5%;
  @media (max-width: $mobileWidth) {
    font-size: 5vmin;
  }
}

.ticker-buysell {
  font-size: 2vmin;
  padding-top: 1%;
  @media (max-width: $mobileWidth) {
    font-size: 4vmin;
  }
}

.hidden {
  visibility: hidden;
}

.expanded-ticker {
  @if $isDebug {
    background-color: green;
  }
  margin: 4vmin;
  text-align: center;
  height: 90%;
  background-size: cover;
  box-shadow: 0 0 16px 16px $backgroundColor inset;
  @media (max-width: $mobileWidth) {
    height: auto;
  }
}

.expanded-ticker-background-overlay {
  background-color: rgba($backgroundColor, 0.7);
  height: 100%;
  font-size: 4vmin;
}

.expanded-ticker-close-icon {
  @if $isDebug {
    background-color: green;
  }
  cursor: pointer;
  display: flex;
  float: right;
  padding-top: 9.5%;
  padding-right: 10%;
}

.expanded-ticker-title {
  @if $isDebug {
    background-color: red;
  }
  padding-top: 10%;
  width: 80%;
  font-size: 5vmin;
  @media (max-width: $mobileWidth) {
    padding-top: 12%;
    padding-left: 5%;
    font-size: 6.5vmin;
  }
}

.expanded-ticker-subtitle {
  font-size: 2.5vmin;
  @media (max-width: $mobileWidth) {
    font-size: 3.5vmin;
  }
}

.expanded-ticker-orderbook {
  @if $isDebug {
    background-color: gray;
  }
  padding-top: 12%;
  width: 45%;
  margin-left: 5%;
  float: left;
  @media (max-width: $mobileWidth) {
    float: none;
    width: auto;
    margin: 0 auto;
  }
}

.expanded-ticker-orderbook-title {
  @if $isDebug {
    background-color: teal;
  }
  font-size: 3.5vmin;
  @media (max-width: $mobileWidth) {
    font-size: 7vmin;
  }
}

.expanded-ticker-orderbook-content {
  padding-top: 2%;
  font-size: 3.2vmin;
  @media (max-width: $mobileWidth) {
    font-size: 6vmin;
  }
}

.expanded-ticker-neworder {
  @if $isDebug {
    background-color: darkgreen;
  }
  padding-top: 12%;
  width: 45%;
  margin-right: 5%;
  float: left;
  @media (max-width: $mobileWidth) {
    width: auto;
    margin: 0 auto;
    float: none;
  }
}

.expanded-ticker-neworder-button {
  font-size: 2.8vmin;
  margin-bottom: 1%;
  margin-top: 4%;
  padding: 1%;
  width: 80%;
  @media (max-width: $mobileWidth) {
    font-size: 5vmin;
    margin: auto;
    margin-bottom: 3%;
    width: 75%;
  }
}

.expanded-ticker-interest {
  @if $isDebug {
    background-color: orange;
  }
  padding-top: 40%;
  font-size: 3.5vmin;
  @media (max-width: $mobileWidth) {
    padding-top: 5%;
    font-size: 6.5vmin;
    width: 80%;
    margin: 0 auto;
  }
}

.expanded-ticker-interest-sub {
  padding-top: 0.5%;
  font-size: 2.8vmin;
  @media (max-width: $mobileWidth) {
    padding-top: 2%;
    padding-bottom: 15%;
    font-size: 5vmin;
  }
}

.expanded-ticker-limitmarketorders {
  @if $isDebug {
    background-color: darkblue;
  }
  padding-top: 12%;
  @media (max-width: $mobileWidth) {
    padding-top: 5%;
  }
}

.expanded-ticker-limitmarketorders-title {
  font-size: 3.5vmin;
  padding-bottom: 0.5%;
  @media (max-width: $mobileWidth) {
    font-size: 5.5vmin;
    padding-bottom: 5%;
  }
}

.expanded-ticker-limitmarketorders-buysellbutton {
  width: 8%;
  font-size: 2.5vmin;
  padding: 0.3%;
  margin: 0.3%;
  margin-bottom: 4%;
  display: inline-block;
  @media (max-width: $mobileWidth) {
    font-size: 4.5vmin;
    width: 60%;
    padding: 1%;
    margin: 0;
    margin-bottom: 2%;
  }
}

.expanded-ticker-limitmarketorders-buysellbutton-active {
  font-weight: bold;
  border: 2px solid white !important;
}

.expanded-ticker-limitmarketorders-content {
  font-size: 3vmin;
  padding-bottom: 0.5%;
  @media (max-width: $mobileWidth) {
    font-size: 6vmin;
    padding-bottom: 7%;
  }
}

.expanded-ticker-limitmarketorders-currentprice {
  padding-top: -4%;
  font-size: 2.8vmin;
  @media (max-width: $mobileWidth) {
    font-size: 5vmin;
  }
}

.expanded-ticker-limitmarketorders-input {
  margin-left: 1%;
  margin-right: 1%;
  width: 10%;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 3px;
  color: white;
  font-size: 2.2vmin;
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  @media (max-width: $mobileWidth) {
    width: 50%;
    font-size: 4.5vmin;
    margin-top: 3%;
    margin-left: 25%;
    margin-right: 25%;
    padding: 1%;
    text-align: center;
  }
}

.expanded-ticker-limitmarketorders-submit {
  margin: 5% auto;
  padding: 0.5%;
  width: 30%;
  font-size: 2.8vmin;
  @media (max-width: $mobileWidth) {
    width: 70%;
    font-size: 6.5vmin;
  }
}

